#collapsible-nav-dropdown{
    color: white;
}


#collapsible-nav-dropdend1,
#collapsible-nav-dropdend3,
#collapsible-nav-dropdend2
{
    color: black;
    padding-left: 15px;

}
#collapsible-nav-dropdend1:hover,
#collapsible-nav-dropdend3:hover,
#collapsible-nav-dropdend2:hover,
#sales-nav:hover{
    color: black;
    background-color: #dddddd;
    
}
.dropdown .dropdown-menu{
    display: none;
}
.dropdown:hover > .dropdown-menu , .dropend:hover > .dropdown-menu{
    display: block;
}


#consultancy-1:hover,
#consultancy-2:hover,
#consultancy-3:hover,
#consultancy-4:hover{
    color: black;
    background-color: #dddddd;
}
#manufacturing-1:hover,
#manufacturing-2:hover,
#manufacturing-3:hover,
#manufacturing-4:hover{
    color: black;
    background-color: #dddddd;
}
#services-1:hover,
#services-2:hover,
#services-3:hover{
    color: black;
    background-color: #dddddd;
}

button{
    color: white;
}